import React, {Suspense} from "react";
import {useRoutes} from "react-router-dom";

//components
import Root from "./Root";

//home
const Home = React.lazy(() => import('../pages/custom/Home'));
const HomeRU = React.lazy(() => import('../pages/custom/HomeRU'));
const HomeEN = React.lazy(() => import('../pages/custom/HomeEN'));
const Thanks = React.lazy(() => import('../pages/custom/Thanks'));
const ThanksRU = React.lazy(() => import('../pages/custom/ThanksRU'));
const ThanksEN = React.lazy(() => import('../pages/custom/ThanksEN'));
const Privacy = React.lazy(() => import('../pages/custom/Privacy'));
const PrivacyEN = React.lazy(() => import('../pages/custom/PrivacyEN'));
const PrivacyRU = React.lazy(() => import('../pages/custom/PrivacyRU'));
const Terms = React.lazy(() => import('../pages/custom/Terms'));
const TermsEN = React.lazy(() => import('../pages/custom/TermsEN'));
const TermsRU = React.lazy(() => import('../pages/custom/TermsRU'));

const loading = () => <div className=""></div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component/>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes([
        {
            //root route
            path: '/',
            element: <LoadComponent component={Home}/>,
        },
        {
            //public routes
            path: '/',
            children: [
                {
                    path: 'ru',
                    element: <LoadComponent component={HomeRU}/>,
                },
                {
                    path: 'en',
                    element: <LoadComponent component={HomeEN}/>,
                },
            ]
        },
        {
            path: '/thanks',
            element: <LoadComponent component={Thanks}/>,
        },
        {
            path: 'ru/thanks',
            element: <LoadComponent component={ThanksRU}/>,
        },
        {
            path: 'en/thanks',
            element: <LoadComponent component={ThanksEN}/>,
        },
        {
            path: '/privacy-policy',
            element: <LoadComponent component={Privacy}/>,
        },
        {
            path: 'en/privacy-policy',
            element: <LoadComponent component={PrivacyEN}/>,
        },
        {
            path: 'ru/privacy-policy',
            element: <LoadComponent component={PrivacyRU}/>,
        },
        {
            path: '/terms-and-conditions',
            element: <LoadComponent component={Terms}/>,
        },
        {
            path: 'en/terms-and-conditions',
            element: <LoadComponent component={TermsEN}/>,
        },
        {
            path: 'ru/terms-and-conditions',
            element: <LoadComponent component={TermsRU}/>,
        },
    ])
}

export default AllRoutes;
